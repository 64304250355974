<template>
  <Header2
      is-home="true"
      title="Hotel Owners"
      lead="Give your customers a night's sleep they will remember"
      bg-image="hotel-room.jpg"/>
  <main>
    <section class="container get-started">
      <div class="row justify-content-center">
        <div class="col col-lg-8">
          <p>The quality of our bedding, combined with our high level of customer service has made us the natural
            choice of hundreds of hotels throughout the Southeast. If you are looking to furnish your hotel, please
            don't hesitate to contact us and speak with one of our qualified representatives.</p>
          <p>We are here to meet
              your needs, and can provide you with spec sheets and bulk purchase information. Please feel free to
            <router-link to="browse-bedding">browse our bedding lines</router-link> and <router-link
                to="contact">contact
              us</router-link> for
                                                                                                    information on
              how to
              order.</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Header2 from '@/components/Header'

export default {
  name: 'HotelMattresses',
  components: { Header2 },
  mounted () {
    document.title = 'Hotel Mattresses by Thomasville Bedding'
  }
}
</script>

<style scoped>
.get-started {
  margin: 100px auto;
}
</style>
