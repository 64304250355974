<template>
  <Header
      title="Bedding Accessories"
      lead="Quality products at wholesale pricing"
      bg-image="bedding-banner.jpg"/>
  <main>
    <section class="container-xl intro-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-5">
          <h2 class="">Pillows</h2>
          <p class="lead">All of our pillows are 100% Natural Talalay Latex </p>
          <p class="mb-0">Available in:</p>
          <ul class="list-unstyled">
            <li class="">Standard - $<span class="price">64.95</span></li>
            <li class="">Queen - $<span class="price">74.95</span></li>
            <li class="">King - $<span class="price">84.95</span></li>
          </ul>
        </div>
        <div class="col-12 col-md-5 mb-4 mb-md-0 order-1">
          <img src="../assets/img/pillows.jpg" alt="A stack of white pillows of different sizes"
               class="img-fluid shadow-lg mx-auto">
        </div>
      </div>
      <div class="divider text-center">
        <img src="../assets/img/divider.svg" alt="" role="presentation" class="img-fluid mx-auto">
      </div>
      <div class="row justify-content-between">
        <div class="col-12 col-md-5 mb-4 mb-lg-0">
          <h2 class="">Quilted Mattress Protectors</h2>
          <p class="lead">Waterproof, breathable, hypoallergenic, machine washable</p>
          <p class="mb-0">Available in:</p>
          <ul class="list-unstyled">
            <li class="">Twin - $<span class="price">65.00</span></li>
            <li class="">Twin XL - $<span class="price">69.00</span></li>
            <li class="">Full - $<span class="price">75.00</span></li>
            <li class="">Queen - $<span class="price">85.00</span></li>
            <li class="">King - $<span class="price">99.00</span></li>
          </ul>
        </div>
        <div class="col-12 col-md-5">
          <h2 class="">Bed Frames</h2>

          <p class="mb-0">Available in:</p>
          <ul class="list-unstyled">
            <li class="">Twin/Full - $<span class="price">44.95</span></li>
            <li class="">Queen - $<span class="price">74.95</span></li>
            <li class="">King - $<span class="price">99.95</span></li>
          </ul>
          <ul class="list-unstyled">
            <li class="">Queen 10" High Profile - $<span class="price">119.95</span></li>
            <li class="">King 10" High Profile - $<span class="price">139.95</span></li>
          </ul>
          <ul class="list-unstyled">
            <li class="">Queen Metal Platform base - $<span class="price">269.00</span></li>
            <li class="">King Metal Platform base - $<span class="price">299.00</span></li>
          </ul>
        </div>
      </div>
      <div class="row text-center ">
        <div class="col-12">
          <div class="divider text-center">
            <img src="../assets/img/divider.svg" alt="" role="presentation" class="img-fluid mx-auto">
          </div>
          <p class="lead">Don't see what you need? Give us a call.</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'BeddingAccessories',
  components: { Header }
}
</script>

<style scoped lang="scss">
.intro-section {
  margin-top: 100px;
}
.divider {
  margin: 80px auto;

  > img {
    max-width: 25em;
  }
}
li {
  font-size: 1.25em;
  font-weight: normal;
}
 @media screen and (min-width: 992px) {
   .intro-section {
     margin-top: 150px;
   }
 }
</style>
